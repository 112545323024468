import { createSelector, createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit';
import update from 'immutability-helper';

import { RootState } from '@store';
import { Sort } from '@utils/types';
import { defaultSortParams, DEFAULT_PAGE_NUMBER, ViewPageSize } from '@helpers';
import { Nullable } from 'src/globalTypes';
import { NetworkUser } from '@state/users/types';
import { File } from '@features/files/types';
import { FileBreadcrumb, FilesViewType, MIN_GRID_ITEM_SIZE, ROOT_DIRECTORY } from './types';

interface PrevLocationData {
  parentDirId: string;
  parentPageNumber: number;
  parentScrollPosition?: number;
}

interface FilesState {
  viewType: FilesViewType;
  selectedIds: EntityId[];
  selectedFiles: any;
  searchTerm: string;
  downloadStatus: {
    isStarted: boolean;
    errorMsg: string | null;
    percent: number;
    isFinish: boolean;
    isCollapsed: boolean;
  };
  breadcrumbs: FileBreadcrumb[];
  gridItemSize: number;
  sort: Sort;
  scrollPosition: number;
  pageSize: ViewPageSize;
  pageNumber: number;
  prevLocationData: Nullable<PrevLocationData>;
  activeUser?: Nullable<NetworkUser>;
  ownerId?: Nullable<string>;
  selectMode?: string | null;
  isUpload: {
    id: number;
    mode: string | undefined;
  } | null;
  isNewFolderModal?: boolean;
  deleteAllFilesModal: boolean;
}

interface SelectFilesPayload {
  ids: EntityId[];
  selected: boolean;
}

const initialState: FilesState = {
  viewType: 'list',
  selectedIds: [],
  selectedFiles: [],
  searchTerm: '',
  breadcrumbs: [
    {
      name: 'HOME',
      id: '.',
      isFile: false
    } as FileBreadcrumb
  ],
  downloadStatus: {
    isStarted: false,
    errorMsg: null,
    percent: 0,
    isFinish: false,
    isCollapsed: true
  },
  gridItemSize: MIN_GRID_ITEM_SIZE,
  sort: defaultSortParams,
  scrollPosition: 0,
  pageSize: ViewPageSize.LIST,
  pageNumber: DEFAULT_PAGE_NUMBER,
  prevLocationData: null,
  activeUserId: null,
  ownerId: null,
  selectMode: null,
  isUpload: null,
  isNewFolderModal: false,
  deleteAllFilesModal: false
} as FilesState;

const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    cleanupState: () => ({ ...initialState }),
    changeSort: (state: FilesState, { payload }: PayloadAction<Sort>) => {
      state.sort = payload;
    },
    resetSort: (state) => {
      state.sort = defaultSortParams;
    },
    changeViewType: (state: FilesState, action: PayloadAction<FilesViewType>) => {
      state.viewType = action.payload;
      state.selectedIds = [];
    },
    changeGridSize: (state: FilesState, action: PayloadAction<number>) => {
      state.gridItemSize = action.payload;
    },
    changeSelected: (state: FilesState, action: PayloadAction<SelectFilesPayload>) => {
      const { selected, ids } = action.payload;
      return selected
        ? update(state, { selectedIds: { $push: ids } })
        : {
            ...state,
            selectedIds: state.selectedIds.filter((selectedId) => !ids.includes(selectedId))
          };
    },
    setSelectedFiles: (state: FilesState, action: PayloadAction<File>) => {
      const file = action.payload;

      return state.selectedFiles.find(
        (selectedFile: File) => selectedFile.file_id === file?.file_id
      )
        ? {
            ...state,
            selectedFiles: state.selectedFiles.filter(
              (selectedFile: File) => selectedFile.file_id !== file?.file_id
            )
          }
        : {
            ...state,
            selectedFiles: [...state.selectedFiles, file]
          };
    },
    clearSelectedFiles: (state: FilesState) => {
      state.selectedFiles = [];
    },
    setSelectedIds: (state: FilesState, action: PayloadAction<EntityId[]>) => {
      state.selectedIds = action.payload;
    },
    setPreviewSelected: (state: FilesState, action: PayloadAction<EntityId>) => {
      state.selectedIds = [action.payload];
    },
    clearSelectedFilesIds: (state: FilesState) => {
      state.selectedIds = [];
    },
    changeSearchTerm: (state: FilesState, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    changeDownloadStatus: (state: FilesState, action: PayloadAction<anys>) => {
      state.downloadStatus = {
        ...state.downloadStatus,
        ...action.payload
      };
    },
    resetDownloadStatus: (state: FilesState) => {
      state.downloadStatus = initialState.downloadStatus;
    },
    sliceBreadcrumbsById: (state: FilesState, action: PayloadAction<string>) => {
      if (action.payload === ROOT_DIRECTORY) {
        state.breadcrumbs = initialState.breadcrumbs;
      } else {
        state.breadcrumbs = state.breadcrumbs.slice(
          0,
          state.breadcrumbs.map(({ id }) => id).indexOf(action.payload) + 1
        );
      }
    },
    addToBreadcrumbs: (state: FilesState, action: PayloadAction<FileBreadcrumb>) => {
      return update(state, { breadcrumbs: { $push: [action.payload] } });
    },
    removeFromBreadcrumbs: (state: FilesState, action: PayloadAction<string>) => {
      const deleteItemIndex = state.breadcrumbs
        .map((breadcrumb) => breadcrumb.id)
        .indexOf(action.payload);
      if (deleteItemIndex !== -1) {
        state.breadcrumbs = state.breadcrumbs.slice(0, deleteItemIndex);
      }
    },
    removeFinishBreadcrumb: (state: FilesState) => {
      state.breadcrumbs = state.breadcrumbs.slice(0, -1);
    },
    overwriteBreadcrumbs: (state: FilesState, { payload }: PayloadAction<FileBreadcrumb[]>) => {
      state.breadcrumbs = payload;
    },
    setActiveUser: (state: FilesState, { payload }: PayloadAction<NetworkUser>) => {
      state.activeUser = payload;
    },
    setOwnerId: (state: FilesState, { payload }: PayloadAction<string>) => {
      state.ownerId = payload;
    },
    setPrevLocationData: (state: FilesState, { payload }: PayloadAction<PrevLocationData>) => {
      state.prevLocationData = payload;
    },
    resetPrevLocationData: (state: FilesState) => {
      state.prevLocationData = null;
    },
    setPageNumber: (state: FilesState, { payload }: PayloadAction<number>) => {
      state.pageNumber = payload;
    },
    setPageSize: (state: FilesState, { payload }: PayloadAction<ViewPageSize>) => {
      state.pageSize = payload;
    },
    setScrollPosition: (state: FilesState, { payload }: PayloadAction<number>) => {
      state.scrollPosition = payload;
    },
    resetPageSizeAndPageNumber: (state: FilesState) => {
      state.pageNumber = DEFAULT_PAGE_NUMBER;
      state.pageSize = ViewPageSize.LIST;
    },
    setSelectMode: (state: FilesState, { payload }: PayloadAction<string>) => {
      state.selectMode = payload;
    },
    setIsUpload: (
      state: FilesState,
      { payload }: PayloadAction<{ id: number; mode: string | undefined } | null>
    ) => {
      state.isUpload = payload;
    },
    setIsNewFolderModal: (state: FilesState, { payload }: PayloadAction<boolean>) => {
      state.isNewFolderModal = payload;
    },
    setDeleteAllFilesModal: (state: FilesState, { payload }: PayloadAction<boolean>) => {
      state.deleteAllFilesModal = payload;
    }
  }
});

export default filesSlice.reducer;

export const {
  changeSearchTerm: changeFilesSearchTerm,
  changeViewType: changeFilesViewType,
  changeSelected: changeSelectedFiles,
  setPreviewSelected: setPreviewSelectedFileId,
  clearSelectedFilesIds,
  changeSort: changeSortingState,
  changeGridSize: changeFilesGridSize,
  sliceBreadcrumbsById: sliceFileBreadcrumbsById,
  addToBreadcrumbs: addToFileBreadcrumbs,
  removeFromBreadcrumbs: removeFromFileBreadcrumbs,
  removeFinishBreadcrumb,
  resetSort,
  setActiveUser,
  overwriteBreadcrumbs,
  setOwnerId,
  cleanupState,
  setPrevLocationData,
  resetPrevLocationData,
  setPageNumber,
  setPageSize,
  setScrollPosition,
  setSelectedIds,
  setSelectMode,
  setIsUpload,
  setIsNewFolderModal,
  setSelectedFiles,
  clearSelectedFiles,
  setDeleteAllFilesModal,
  changeDownloadStatus,
  resetDownloadStatus
} = filesSlice.actions;

const selectFilesState = (state: RootState) => state.files;

export const selectedFilesState = (state: RootState): File[] => state.files.selectedFiles;
export const selectFilesViewType = createSelector(selectFilesState, (state) => state.viewType);
export const selectPageSize = createSelector(selectFilesState, (state) => state.pageSize);
export const selectPageNumber = createSelector(selectFilesState, (state) => state.pageNumber);
export const selectFilesSearchTerm = createSelector(selectFilesState, (state) => state.searchTerm);
export const selectSelectMode = createSelector(selectFilesState, (state) => state.selectMode);
export const selectIsUpload = createSelector(selectFilesState, (state) => state.isUpload);
export const selectIsNewFolderModal = createSelector(
  selectFilesState,
  (state) => state.isNewFolderModal
);

export const selectDeleteAllFilesModal = createSelector(
  selectFilesState,
  (state) => state.deleteAllFilesModal
);

export const selectSelectedFilesIds = createSelector(
  selectFilesState,
  (state) => state.selectedIds
);
export const selectFilesGridItemSize = createSelector(
  selectFilesState,
  (state) => state.gridItemSize
);
export const selectFilesBreadcrumbs = createSelector(
  selectFilesState,
  (state) => state.breadcrumbs
);

export const selectDownloadStatus = createSelector(
  selectFilesState,
  (state) => state.downloadStatus
);

export const searchValue = createSelector(selectFilesState, (state) => state.searchTerm);
export const selectActiveFileId = createSelector(
  selectFilesBreadcrumbs,
  (breadcrumbs) => breadcrumbs.filter((breadcrumb) => breadcrumb.isFile).pop()?.id ?? ''
);
export const selectActiveDirectory = createSelector(
  selectFilesBreadcrumbs,
  (breadcrumbs) =>
    breadcrumbs.filter((breadcrumb) => !breadcrumb.isFile && !breadcrumb.isUser).pop() ??
    ({ id: ROOT_DIRECTORY, isFile: false } as FileBreadcrumb)
);
export const selectActiveUser = createSelector(selectFilesState, (state) => state.activeUser);
export const selectOwnerId = createSelector(selectFilesState, (state) => state.ownerId);
export const selectPrevLocationData = createSelector(
  selectFilesState,
  (state) => state.prevLocationData
);

export const selectScrollOffsetItem = createSelector(
  selectFilesState,
  (state) => state.prevLocationData?.parentScrollPosition
);

export const selectScrollPosition = createSelector(
  selectFilesState,
  (state) => state.scrollPosition
);
