import styled from 'styled-components';

const handleBackgroundType = (color: string) => {
  switch (color) {
    case 'error':
      return '#fce1e1';
    case 'finish':
      return '#e7f3e2';
    default:
      return '#6ecdc5';
  }
};
const handleBorderType = (color: string) => {
  switch (color) {
    case 'error':
      return '#f56342';
    case 'finish':
      return '#89c16e';
    default:
      return '#6ecdc5';
  }
};
const handleIconType = (color: string) => {
  switch (color) {
    case 'error':
      return '#f56342';
    case 'finish':
      return '#89c16e';
    default:
      return '#252a34';
  }
};

export const Container = styled.div<{ isCollapsed: boolean }>`
  position: absolute;
  right: 0px;
  bottom: 40px;
  display: flex;
  align-items: center;
  max-height: 150px;
  transition: transform 0.5s ease-in-out; /* Плавное перемещение контейнера вправо */
  transform: ${({ isCollapsed }) =>
    isCollapsed
      ? 'translateX(calc(100% - 60px))'
      : 'translateX(0)'}; /* Плавное сворачивание контейнера с текстом */
`;

export const DownloadIconWrapper = styled.div<{ status: string }>`
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50% 0% 0% 50%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-right: -2px;
  background-color: ${({ status }) => handleBackgroundType(status)};
  border: 2px solid ${({ status }) => handleBorderType(status)};
  border-right-color: transparent;
  z-index: 999; /* Оставляем иконку видимой на переднем плане */
`;

export const DownloadIcon = styled.div<{ status: string }>`
  width: 30px;
  height: 30px;
  background-color: ${({ status }) => handleIconType(status)};
  mask: url('/img/download_icon.svg') no-repeat center / contain;
  -webkit-mask: url('/img/download_icon.svg') no-repeat center / contain;
  margin-right: -5px;
`;

export const InformationBlockWrapper = styled.div<{ isCollapsed: boolean; status: string }>`
  border: 2px solid ${({ status }) => handleBorderType(status)};
  border-radius: 10px;
  background-color: ${({ status }) => handleBackgroundType(status)};
  height: 100%; /* Блок информации занимает всю высоту контейнера */
  padding: 15px;
  display: flex;
  flex-direction: column; /* Размещаем текст по вертикали */
  justify-content: center; /* Центрируем текст по вертикали */
  align-items: flex-start;
  overflow: hidden;
  flex-grow: 1; /* Блок информации растягивается по ширине */
`;

export const InformationText = styled.p`
  font-size: 1rem;
  font-weight: 700;
  color: #252a34;
  margin: 0;
  flex-grow: 1; /* Текст занимает всё доступное пространство внутри родителя */
  display: flex;
  max-width: 400px;
  text-align: center;
  align-items: center; /* Центрируем текст по вертикали */
  height: 100%; /* Текст занимает всю высоту блока */
  overflow: hidden;
  white-space: normal; /* Текст переносится */
`;
