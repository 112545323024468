import React, { useState } from 'react';
import { useAppSelector } from '@hooks/useStore';
import { resetDownloadStatus, selectDownloadStatus } from '@features/files/filesSlice';
import { useDispatch } from 'react-redux';
import {
  Container,
  DownloadIcon,
  DownloadIconWrapper,
  InformationBlockWrapper,
  InformationText
} from './styles';

export const DownloadStatus = () => {
  const dispatch = useDispatch();
  const { isStarted, percent, isFinish, errorMsg } = useAppSelector(selectDownloadStatus);
  const [isCollapsed, setCollapsed] = useState(false);

  const handleClose = () => {
    dispatch(resetDownloadStatus());
  };
  const toggleCollapse = () => {
    if (isFinish && !isCollapsed) {
      handleClose();
    } else {
      setCollapsed(!isCollapsed);
    }
  };

  // eslint-disable-next-line no-nested-ternary
  const status = errorMsg ? 'error' : isFinish ? 'finish' : 'progress';
  if (!isFinish && !isStarted && !errorMsg) {
    return null;
  }

  return (
    <Container isCollapsed={isCollapsed}>
      <DownloadIconWrapper status={status} onClick={toggleCollapse}>
        <DownloadIcon status={status} />
      </DownloadIconWrapper>

      <InformationBlockWrapper isCollapsed={isCollapsed} status={status}>
        <InformationText>
          {errorMsg ||
            (isFinish
              ? 'Your download is complete! Please check the download folder on your device for your files.'
              : `Your download is in progress! Large files may take some time to download. When complete, you'll see a notification here.`)}
        </InformationText>
      </InformationBlockWrapper>
    </Container>
  );
};
